.slider {
	position: relative;
	margin-bottom: 20px; }

.swiper-container-horizontal.slider {
	> .swiper-pagination-bullets {
		.swiper-pagination-bullet {
			width: 46px;
			height: 4px;
			margin: 0 5px;
			background-color: #7B7B7B;
			border-radius: 0;
			opacity: 1;
			&.swiper-pagination-bullet-active {
				background-color: #fff; } } } }

.swiper-container-horizontal > .slider__pagination {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 44px;
	margin: auto;
	z-index: 3; }

.slider__item {
	height: auto;
	min-height: 640px;
	padding-top: 80px;
	padding-bottom: 50px;
	background-color: $base;
	overflow: hidden;
	picture {
		@include posCenter; } }

.slider__container {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%; }

.slider__info {
	max-width: 540px;
	color: #fff;
	h1, .h1 {
		opacity: 0;
		transform: translateX(60px);
		transition: all .5s ease-out .3s; }
	p {
		margin-bottom: 32px;
		opacity: 0;
		transform: translateX(80px);
		transition: all .5s ease-out .4s; }
	.btn {
		min-width: 256px;
		opacity: 0;
		transform: translateX(100px);
		transition: opacity .5s ease-out .5s, transform .5s ease-out .5s; } }

.slider {
	.swiper-slide-active {
		h1, .h1, p, .btn {
			opacity: 1;
			transform: translateX(0); } } }

/*.slider__video
	display: none */

.slider__video {
	display: block; }
@media only screen and (min-width: 1200px) {
	/*.slider__video
		display: block */
	/*.slider__video-poster
		display: none */
	.slider {
		margin-bottom: 52px; }
	.slider__item {
		min-height: 715px; }
	.swiper-container-horizontal.slider {
		> .swiper-pagination-bullets {
			.swiper-pagination-bullet {
				width: 96px;
				margin: 0 10px; } } }
	.swiper-container-horizontal > .slider__pagination {
		justify-content: flex-start;
		bottom: 60px; } }
