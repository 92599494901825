.top-news {
	width: 100%;
	color: #000; }

.top-news__item {
	@extend .events__item;
	width: 100%; }

.top-news__img {
	@extend .events__img; }

.top-news__info {
	background-color: #fff;
	@extend .events__info;
	.icon {
		fill: $base; }
	.btn {
		margin-bottom: 24px; } }

.top-news__info_black {
	padding: 32px 0 0;
	background-color: $base;
	color: #fff;
	.icon {
		fill: #fff; }
	.top-news__section-name {
		background-color: $dark-gray; }
	h1, .h1 {
		margin-bottom: 16px;
		font-weight: 400;
		font-size: 22px;
		line-height: 1.2;
		a {
			color: #fff; } }
	.show-more-link {
		color: #fff; } }


.top-news__section-name {
	display: inline-block;
	align-self: end;
	padding: 6px 16px;
	margin-bottom: 24px;
	background-color: $base;
	color: #fff;
	text-transform: uppercase;
	font-size: 13px;
	font-family: $ttl;
	letter-spacing: .04em; }

.top-news__date {
	display: block;
	margin-bottom: 24px;
	font-family: $ttl;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: .04em; }

@media only screen and (min-width: 640px) {
	.top-news {
		display: flex; }
	.top-news__info_black {
		padding: 0 16px 0 0; } }

@media only screen and (min-width: 1200px) {
	.top-news__info {
		.btn {
			margin-bottom: 32px; } }
	.top-news__item {
		margin-bottom: 64px; }
	.top-news__date {
		margin-bottom: 32px; }
	.top-news__info_black {
		padding-right: 64px;
		h1, .h1 {
			margin-bottom: 24px;
			font-size: 32px;
			line-height: 1.3; } } }
