.tabs__catalog-carousel {
	.main__container {
		overflow: hidden; } }

.catalog-carousel__body {
	position: relative; }

.catalog-carousel {
	position: relative;
	.catalog__col {
		padding: 0;
		margin-bottom: 0;
		height: auto; }
	.swiper-button-next, .swiper-button-prev {
		display: none;
		top: -52px;
		.icon {
			fill: #fff; } }
	&.swiper-container-initialized {
		.swiper-button-next:not(.swiper-button-lock), .swiper-button-prev:not(.swiper-button-lock) {
			display: flex; } } }

.tabs__catalog-carousel {
	margin-bottom: 36px; }

.swiper-container-horizontal .catalog-carousel__scrollbar {
	margin-top: 36px;
	position: relative;
	background-color: $gray;
	z-index: 50;
	height: 4px;
	width: 100%; }

.catalog-carousel-shadow {
	@include posCenter; }

.swiper-scrollbar-lock + .catalog-carousel-shadow {
	display: none; }

@media only screen and (max-width: 1360px) {
	.catalog-carousel {
		overflow: visible; } }

@media only screen and (min-width: 1200px) {
	.tabs__catalog-carousel {
		margin-bottom: 64px; }
	.swiper-container-horizontal .catalog-carousel__scrollbar {
		margin-top: 48px; } }

@media only screen and (min-width: 1361px) {
	.catalog-carousel_not-first {
		&:before {
			content: '';
			width: 76px;
			z-index: 2;
			pointer-events: none;
			@include posLeftCenter;
			background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 90%); } }
	.catalog-carousel_first {
		&:after {
			content: '';
			width: 76px;
			z-index: 2;
			pointer-events: none;
			@include posRightCenter;
			background: linear-gradient(-90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 90%); } } }

