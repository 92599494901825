.main-top {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 560px;
	padding-top: 90px;
	margin-bottom: 32px;
	background-color: #000;
	.events__time-top:before {
		background-color: #fff; }
	.show-more-link {
		+ .country {
			padding-top: 16px; } } }

.main-top__bg {
	@include posCenter;
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover; } }

.main-top__info {
	display: flex;
	flex-wrap: wrap;
	flex: 1; }

.main-top__info-body {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex: 1;
	min-height: 420px; }

.main-top__info_bottom {
	.main-top__info-body {
		align-items: flex-end; }
	.main-top__text {
		> *:last-child {
			margin-bottom: 0; } } }

.main-top__container {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	> h1, .h1 {
		color: #fff; } }

.main-top__text {
	max-width: 712px;
	color: #fff;
	padding-bottom: 32px;
	p {
		margin-bottom: 24px; }
	a:not(.btn) {
		color: #fff; }
	.icon {
		fill: #fff; } }

.main-top__img {
	display: block;
	position: relative;
	width: 100%;
	text-align: center;
	margin-bottom: 32px; }

.country {
	display: flex;
	align-items: center;
	margin-bottom: 28px;
	img {
		margin: -2px 9px 0 0; } }

.main-top__mobile {
	width: 100%;
	color: #fff;
	a {
		text-decoration: none;
		color: #fff; }
	.icon {
		fill: #fff; }
	.top-news__section-name {
		background-color: $dark-gray; }
	h1, .h1 {
		margin-bottom: 16px;
		font-size: 22px;
		font-weight: 400;
		line-height: 1.2; } }

.main-top_btn-bottom {
	.main-top__info-body {
		padding-bottom: 64px; }
	.main-top__text {
		p {
			margin-bottom: 16px; } } }

.btn_bottom {
	position: absolute;
	left: 0;
	bottom: 0;
	margin-bottom: 0; }

.main-top__info_small-h {
	h1, .h1 {
		margin-bottom: 16px;
		font-size: 22px;
		font-weight: 400;
		line-height: 1.2; } }

.main-top__tag {
	display: inline-block;
	margin-bottom: 24px;
	padding: 6px 16px;
	font-family: $ttl;
	font-size: 13px;
	letter-spacing: .04em;
	text-transform: uppercase;
	background-color: #fff;
	color: $base; }

@media only screen and (max-width: 767px) {
	.main-top__img {
		&:after {
			content: '';
			position: absolute;
			left: -16px;
			right: -16px;
			bottom: 0;
			margin: auto;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2); } } }


@media only screen and (min-width: 768px) {
	.main-top__text {
		flex-grow: 1;
		padding-right: 16px;
		width: 50%; }
	.main-top__img {
		order: 1;
		height: 100%;
		width: 50%;
		margin-bottom: 0;
		overflow: hidden;
		picture {
			@include posCenter;
			max-width: 638px; }
		img {
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover; } } }

@media only screen and (min-width: 1200px) {
	.main-top {
		min-height: 680px;
		padding-top: 186px;
		margin-bottom: 64px; }
	.main-top__info-body {
		min-height: 445px; }
	.main-top__text {
		padding-right: 64px;
		padding-bottom: 64px;
		p {
			margin-bottom: 36px; } }
	.country {
		margin-bottom: 40px; }
	h1, .h1 {
		+ .country {
			margin-top: -16px; } }
	.main-top__info_small-h {
		h1, .h1 {
			margin-bottom: 24px;
			font-size: 32px;
			line-height: 1.3; } } }

@media only screen and (min-width: 1440px) {
	.main-top__info-body {
		position: relative; }
	.main-top__text {
		max-width: 980px; } }
