.tabs {
	.show-more-link_mobile {
		margin-top: 34px; } }

.tabs__links-wrp {
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow-x: auto;
	padding-bottom: 10px;
	margin-bottom: 22px; }

.tabs-links {
	display: flex;
	overflow-anchor: none; }

.tabs-links__item {
	padding: 0 12px;
	&:first-child {
		padding-left: 0; } }

.tabs-lnk {
	font-family: $ttl;
	font-size: 18px;
	display: inline-block;
	position: relative;
	margin-bottom: 0;
	padding: 12px 0;
	text-decoration: none;
	white-space: nowrap;
	overflow-anchor: none;
	&:before {
		content: '';
		position: absolute;
		width: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		letter-spacing: 0;
		border-bottom: 3px solid transparent;
		transition: all .2s ease; } }

.tabs-lnk.js-tabs-lnk_active {
	&:before {
		width: 100%;
		border-bottom-color: $base; } }

.js-tab {
	display: none; }

.js-tab_show {
	display: block; }

.tabs-column__link {
	position: relative;
	display: flex;
	padding: 32px 30px 32px 12px;
	align-items: center;
	font-family: $ttl;
	color: #979797;
	border-bottom: 1px solid $gray;
	text-decoration: none;
	.icon {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 12px;
		margin: auto;
		fill: $base; } }

.tabs-column__link.js-tabs-lnk_active {
	background-color: $gray;
	border-bottom-color: $base;
	color: $base;
	transition: color .3s ease;
	.icon {
		transform: rotate(180deg); } }

.tabs-column__link-icon {
	flex-shrink: 0;
	width: 45px;
	height: 45px;
	margin-right: 16px;
	img {
		border-radius: 50%; } }

.tabs-column__tabs-links_tablet {
	display: none; }

.tabs-column {
	@extend .flex-wrp;
	margin: 0 -16px;
	.js-tab_show {
		margin-bottom: 16px; }
	.js-show-hide-link {
		margin-top: 34px; } }

.tabs-column__tabs-links {
	width: 100%;
	padding: 0 16px; }

.tabs-column__info {
	width: 100%;
	padding: 0 16px;
	margin-bottom: 50px; }

@media only screen and (min-width: 768px) {
	.tabs-column__tabs-links {
		width: 40%;
		margin-bottom: 50px; }
	.tabs-column__info {
		width: 60%;
		.js-show-hide-link {
			display: none; }
		.js-hide-text {
			display: block; } }
	.tabs-column__link_mobile {
		display: none; }
	.tabs-column__tabs-links_tablet {
		display: block; } }

@media only screen and (min-width: 1200px) {
	.tabs-column {
		margin: 0 -32px; }
	.tabs-column__tabs-links {
		width: 42.5%;
		padding: 0 32px;
		margin-bottom: 80px; }
	.tabs-column__info {
		width: 57.5%;
		padding: 0 32px;
		margin-bottom: 80px;
		.text img {
			margin-bottom: 32px; } }
	.tabs__links-wrp {
		margin-bottom: 36px;
		overflow-x: visible; }
	.tabs-links__item {
		padding: 0 24px; }
	.tabs-lnk {
		font-size: 20px;
		&:hover {
			&:before {
				width: 100%;
				border-bottom-color: $base; } } }
	.tabs-column__link {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -1px;
			width: 0;
			border-bottom: 1px solid $base;
			transition: width .3s ease; }
		&:hover {
			color: $base;
			&:before {
				width: 100%; } } } }
