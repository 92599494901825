html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, address, em, img, q, samp, small, strong, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, nav, section, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline; }

ol, ul {
	list-style: none; }

img {
	vertical-align: top;
	max-width: 100%;
	height: auto; }

input[type="text"]:focus {
	outline: none; }

button, input, textarea {
	line-height: inherit;
	outline: none; }

button, input, select, textarea {
	font-size: 100%;
	margin: 0;
	vertical-align: baseline;
	font-family: $font;
	font-weight: 400;
	*vertical-align: middle; }

input, button, textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	border-radius: 0; }

input[type='text'], input[type='search'], input[type='tel'], input[type='email'], input[type='number'], input[type='password'] {
	font-size: 16px; }

button, input[type="submit"], input[type="reset"], input[type="button"] {
	cursor: pointer; }

b, strong {
	font-weight: 500; }

a {
	text-decoration: underline;
	color: $base;
	&:hover {
		text-decoration: none; } }

em, i {
	font-style: italic; }

input {
	&[disabled] {
		cursor: default;
		background-color: #cfcfcf; }
	&[type=checkbox], &[type=radio] {
		*height: 13px;
		padding: 0;
		*width: 13px; }
	&[type=search] {
		&::-webkit-search-decoration, &::-webkit-search-cancel-button {
			-webkit-appearance: none; } } }

input[type='number'] {
	-moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0; }

input::-ms-clear {
	display: none;
	width: 0;
	height: 0; }

html {
	* {
		box-sizing: border-box;
		text-size-adjust: 100%;
		font-size: 100%;
		outline: none;
		&:before, &:after {
			box-sizing: border-box; } } }

body {
	background: #fff;
	color: $base;
	direction: ltr;
	font: 400 16px/1.4 $font; }

/*overflow-anchor: none для прокрутки */
/*при использовании scale родительскому блоку добавить стиль backface-visibility: hidden*/
/*и добавить самой картинке will-change: transform*/
