.text-wrapper {
	position: relative;
	width: 100%;
	max-width: 750px;
	margin: 0 auto;
	transition: all .3s ease-in; }

.title-h2 {
	padding: 48px 0 64px;
	text-align: center;
	h2, .h2 {
		margin-bottom: 0;
		font-size: 18px; } }

.table-wrp {
	overflow-y: auto;
	margin-bottom: 30px;
	> .table-style {
		margin-bottom: 0; } }

.text-s {
	font-size: 15px; }

.text-l {
	font-size: 18px; }

.js-hide-text {
	display: none; }

.js-hide-text_show {
	display: block; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
	font-family: $ttl; }

h2, .h2, h3, .h3, h4, .h4, h6, .h6 {
	margin-bottom: 16px;
	font-weight: 400; }

h1, .h1 {
	margin-bottom: 24px;
	font-size: 34px;
	font-weight: 400;
	line-height: 1.2; }

h2, .h2 {
	font-size: 22px;
	line-height: 1.2; }

h3, .h3 {
	font-size: 18px; }

h4, .h4 {
	font-size: 16px;
	line-height: 1.3; }

h5, .h5 {
	font-size: 14px; }

.ul-style {
	margin-bottom: 16px;
	> li {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 8px;
			width: 5px;
			height: 5px;
			background-color: $base;
			border-radius: 50%; }
		> ul {
			margin-bottom: 0;
			li {
				&:first-child {
					margin-top: 8px; } } }
		> ol {
			margin-bottom: 0;
			li {
				&:first-child {
					margin-top: 8px; } } } } }

.ol-style {
	margin-bottom: 20px;
	counter-reset: step-counter;
	> li {
		position: relative;
		margin-bottom: 10px;
		margin-left: 20px;
		counter-increment: step-counter;
		&:before {
			content: counter(step-counter) '.';
			counter-increment: step;
			position: absolute;
			top: 0;
			left: -20px;
			text-align: center;
			color: $base; }
		ol {
			margin-bottom: 0;
			li {
				padding-bottom: 0;
				margin-top: 10px;
				&:before {
					content: counters(step-counter, '.'); } } }
		ul {
			margin-bottom: 0;
			margin-left: 0;
			li {
				margin-left: 0;
				padding-bottom: 0;
				margin-top: 10px; } } } }

.table-style {
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 30px;
	font-size: 14px;
	td, th {
		padding: 18px 20px; }
	td {
		border: 1px solid $base;
		vertical-align: middle; }
	th {
		font-size: 16px;
		font-weight: 400;
		text-align: left;
		border: 1px solid $base;
		&[align='center'] {
			text-align: center; } } }

.details {
	display: block;
	position: relative;
	margin-bottom: 32px;
	padding: 16px 26px 16px 0;
	border-bottom: 1px solid #d1d1d1;
	text-decoration: none;
	font-weight: 500;
	.icon {
		@include posRightCenter; } }

.text {
	&:last-child {
		margin-bottom: 32px; }
	& > *:last-child {
		margin-bottom: 0!important; }
	blockquote {
		position: relative;
		padding: 20px;
		margin-bottom: 20px; }
	img, img[align="right"], img[align="left"], img[align="center"] {
		display: block;
		margin: 0 auto 32px;
		float: none; }
	.country {
		img {
			margin-bottom: 0; } }
	.video {
		margin-bottom: 32px;
		img {
			margin: 0; } }
	.photos__col {
		img {
			margin: 0; } }
	.gallery-photo {
		img {
			margin: 0; } }
	hr {
		margin: 0;
		border: none;
		border-bottom: 1px solid #E0E0E0; }
	p {
		margin-bottom: 16px; }
	ul {
		&:not(.slick-dots) {
			@extend .ul-style; } }
	ol {
		@extend .ol-style; }
	/*h1, h2, h3, h4, h5, ul, ol, p
		& + img
			margin-top: 0 !important */
	table {
		@extend .table-style;
		margin-left: auto;
		margin-right: auto; }
	[id] {
		&:last-child {
			> *:last-child {
				margin-bottom: 0; } } } }
@media only screen and (min-width: 1200px) {
	h1, .h1 {
		margin-bottom: 32px;
		font-size: 48px; }
	h2, .h2 {
		margin-bottom: 24px;
		font-size: 32px;
		line-height: 1.3; }
	h3, .h3 {
		font-size: 20px; }
	.text {
		&:last-child {
			margin-bottom: 64px; }
		img[align="right"] {
			margin: 20px 0 20px 20px;
			max-width: 50%;
			float: right; }
		img[align="left"] {
			margin: 20px 20px 20px 0;
			float: left;
			max-width: 50%; }
		img {
			margin-bottom: 64px; }
		blockquote {
			padding: 30px; }
		.video {
			margin-bottom: 64px; } }
	.title-h2 {
		padding: 64px 100px 128px;
		h2, .h2 {
			font-size: 32px; } }
	.details {
		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -1px;
			width: 0;
			border-bottom: 1px solid transparent;
			transition: all .3s ease; }
		&:hover {
			&:before {
				width: 100%;
				border-bottom-color: $base; } } } }
@media only screen and (min-width: 1500px) {
	.text-wrapper {
		max-width: 950px; } }
