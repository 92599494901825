.footer {
	position: relative;
	padding: 48px 0;
	background-color: $base;
	color: #fff; }

.footer__logo-wrp {
	margin-bottom: 32px;
	padding-bottom: 30px;
	border-bottom: 1px solid rgba(255, 255, 255, .2); }

.footer__langs {
	position: relative;
	display: flex;
	margin-bottom: 32px;
	border: 1px solid rgba(255, 255, 255, .2);
	&:before {
		content: '';
		@include posCenter;
		width: 1px;
		background-color: rgba(255, 255, 255, .2); }
	.lang {
		width: 50%; } }

.footer__bottom {
	font-size: 12px;
	a {
		text-decoration: none;
		color: #7b7b7b; } }

.footer__nav {
	margin-bottom: 48px;
	font-weight: 500;
	letter-spacing: .3px; }

.footer__nav-col {
	&:first-child {
		.footer__nav-item {
			&:first-child {
				.footer__nav-link {
					border-top: 1px solid rgba(255, 255, 255, 0.2); } } } } }

.footer__nav-link {
	display: block;
	padding: 24px 0;
	text-decoration: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	color: #fff; }

.footer__subscribe {
	margin-bottom: 32px;
	color: #d1d1d1;
	p {
		margin-bottom: 32px; }
	.btn {
		width: 100%;
		max-width: 270px; } }

.js-up {
	position: fixed;
	right: -65px;
	bottom: 16px;
	cursor: pointer;
	width: 40px;
	height: 40px;
	border: 1px solid $dark-gray;
	transition: right .3s ease;
	background-color: $base;
	z-index: 5;
	.icon {
		@include posCenter;
		fill: #fff; } }

.js-up_show {
	right: 16px; }

.footer__contacts {
	margin-bottom: 48px;
	font-size: 20px;
	font-family: $ttl;
	a {
		display: inline-block;
		margin-bottom: 16px;
		text-decoration: none;
		color: #fff; } }

.footer__social {
	margin-bottom: 38px; }

@media only screen and (min-width: 640px) {
	.footer__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 32px;
		padding-bottom: 32px;
		border-bottom: 1px solid rgba(255, 255, 255, .2); }
	.footer__logo-wrp {
		padding-bottom: 0;
		margin-bottom: 0;
		border-bottom: none; }
	.footer__langs {
		margin-bottom: 0;
		border: none;
		&::before {
			display: none; }
		.lang_country {
			.js-lang-all {
				right: 0;
				left: auto;
				min-width: 152px; } }
		.lang {
			width: auto; } }
	.footer__center {
		display: flex;
		flex-wrap: wrap; }
	.footer__contacts-wrp {
		width: 45%;
		margin-bottom: 32px; }
	.footer__nav-wrp {
		width: 55%;
		margin-bottom: 32px; }
	.footer__nav {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px; }
	.footer__nav-col {
		width: 50%;
		padding: 0 10px;
		&:first-child {
			.footer__nav-item:first-child {
				.footer__nav-link {
					border-top: none; } } } }
	.footer__nav-link {
		padding: 0;
		border-bottom: none;
		border-top: none; }
	.footer__nav-item {
		margin-bottom: 24px;
		&:last-child {
			margin-bottom: 0; } }
	.footer__social {
		margin-bottom: 0; } }

@media only screen and (min-width: 992px) {
	.footer__center {
		margin: 0 -16px; }
	.footer__contacts-wrp {
		width: 30%;
		padding: 0 16px; }
	.footer__nav-wrp {
		width: 40%; }
	.footer__subscribe-wrp {
		width: 30%;
		padding: 0 16px; }
	.footer__nav {
		margin: 0; }
	.footer__nav-col {
		padding: 0 16px; } }

@media only screen and (min-width: 1200px) {
	.footer {
		padding: 64px 0; }
	.footer__top {
		padding-bottom: 64px;
		margin-bottom: 64px; }
	.footer__center {
		margin-bottom: 64px; }
	.footer__contacts-wrp {
		width: 25%; }
	.footer__nav-wrp {
		width: 42%; }
	.footer__subscribe-wrp {
		width: 33%; }
	.footer__nav-link {
		position: relative;
		display: inline-block;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: -4px;
			width: 0;
			border-bottom: 1px solid transparent;
			transition: all .3s ease; }
		&:hover {
			&:before {
				width: 100%;
				border-bottom-color: rgba(255, 255, 255, 0.5); } } }
	.js-up {
		width: 60px;
		height: 60px; } }

@media only screen and (min-width: 1600px) {
	.js-up_show {
		right: 50px; } }
