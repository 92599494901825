.search-sections-wrp {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		right: 0;
		left: 0;
		bottom: 0;
		margin: auto;
		border-bottom: 1px solid #d1d1d1; } }

.search-sections {
	margin: 0 -16px;
	overflow-y: auto;
	font-size: 15px; }


.search-sections__inner {
	display: flex; }

.search-sections__item {
	position: relative;
	padding: 0 16px;
	z-index: 1;
	white-space: nowrap; }

.search-sections__radio {
	opacity: 0;
	visibility: hidden;
	left: 0;
	top: 0;
	&:checked + .search-sections__label {
		border-bottom: 3px solid #000; } }

.search-sections__label {
	display: inline-block;
	padding: 18px 0;
	text-decoration: none;
	cursor: pointer; }
