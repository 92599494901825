.slider-logo {
	position: relative;
	margin-bottom: 16px; }

.slider-logo__item {
	display: inline-block;
	text-align: center; }

.slider-logo__gradient {
	@include posCenter;
	background-image: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0.1) 30%, rgba(255, 255, 255, 0.1) 70%, #fff);
	pointer-events: none; }


@media only screen and (min-width: 1200px) {
	.slider-logo {
		margin-bottom: 32px; } }
