.catalog {
	&:not(.catalog-carousel) {
		@extend .flex-wrp;
		margin: 0 -16px; }
	.tag {
		position: absolute;
		left: 20px;
		top: 20px;
		text-transform: uppercase;
		z-index: 1; } }

.catalog__col {
	width: 100%;
	padding: 0 16px;
	margin-bottom: 16px; }

.catalog__item {
	position: relative;
	height: 100%;
	background-color: $gray; }

.catalog__img {
	display: block;
	overflow: hidden;
	text-align: center; }

.catalog__item-info {
	padding: 24px;
	h4, .h4 {
		margin-bottom: 12px; }
	a {
		text-decoration: none; } }

@media only screen and (min-width: 640px) {
	.catalog__col {
		width: 50%; } }

@media only screen and (min-width: 768px) {
	.catalog__col {
		width: 33.33333333%; } }

@media only screen and (min-width: 768px) {
	.content__aside {
		+ .content__body .catalog__col {
			width: 50%; } } }

@media only screen and (min-width: 1200px) {
	.catalog__col {
		margin-bottom: 32px; }
	.catalog__item-info {
		padding: 32px; } }

@media only screen and (min-width: 1300px) {
	.catalog__col {
		width: 25%; }
	.content__aside {
		+ .content__body .catalog__col {
			width: 33.33333333%; } } }
