.left-menu__link-wrp {
	position: relative;
	padding: 15px 18px 15px 0;
	.icon {
		@include posRightCenter; } }

.left-menu__link {
	font-size: 20px;
	font-family: $ttl;
	text-decoration: none;
	color: $base; }

.left-submenu {
	display: block; }

.left-menu__item {
	position: relative;
	cursor: pointer;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
		border-top: 1px solid $gray; }
	&:last-child {
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			border-bottom: 1px solid $gray; } } }

.left-menu__item_active {
	.icon {
		transform: rotate(180deg); } }

.left-submenu {
	display: none;
	font-weight: 500; }

.left-submenu_show {
	display: block; }

.left-submenu__item {
	position: relative;
	border-top: 1px solid $gray; }

.left-submenu__link {
	position: relative;
	display: block;
	padding: 20px 16px 20px 0;
	text-decoration: none;
	span {
		position: relative; }
	&:before {
		content: '';
		position: absolute;
		top: -1px;
		bottom: -1px;
		left: 0;
		width: 0;
		pointer-events: none;
		background-color: transparent;
		transition: all .4s ease-in-out; } }

.left-submenu__item_active {
	.left-submenu__link {
		&:before {
			width: calc(100% + 32px);
			left: -16px;
			right: -16px;
			background-color: $gray; }
		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 5px;
			width: 6px;
			height: 6px;
			margin: auto;
			border-radius: 50%;
			background-color: $base; } } }

@media only screen and (min-width: 992px) {
	.left-menu__link-wrp {
		padding: 9px 0;
		display: flex;
		align-items: center;
		.icon {
			position: relative;
			margin-left: 8px; } }
	.left-menu__item {
		&:before, &:after {
			display: none; } }
	.left-submenu__item {
		border-top: none; }
	.left-submenu__link {
		padding: 12px 22px 12px 0;
		&:before {
			top: 0;
			bottom: 0;
			left: -16px; } }
	.left-submenu__item_active {
		.left-submenu__link {
			&:before {
				width: calc(100% + 16px);
				right: 0; }
			&:after {
				right: 16px; } } } }

@media only screen and (min-width: 1200px) {
	.left-submenu__link {
		&:hover {
			&:before {
				width: calc(100% + 16px);
				background-color: $gray; } } } }
