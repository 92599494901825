.contacts__row {
	@extend .flex-wrp;
	margin: 0 -16px; }

.contacts__col {
	width: 100%;
	padding: 0 16px;
	margin-bottom: 32px; }

.contacts__base-item {
	max-width: 378px;
	margin-bottom: 32px;
	font-size: 18px;
	font-family: $ttl;
	a {
		display: inline-block;
		margin-bottom: 4px;
		text-decoration: none; } }

.contacts__item {
	margin-bottom: 48px;
	padding: 16px 0;
	border-top: 1px solid #d1d1d1;
	border-bottom: 1px solid #d1d1d1;
	&:last-child {
		margin-bottom: 0; }
	a {
		display: inline-block;
		margin-bottom: 8px;
		text-decoration: none;
		&:last-child {
			margin-bottom: 0; } } }

@media only screen and (min-width: 640px) {
	.contacts__col {
		width: 50%; } }

@media only screen and (min-width: 1200px) {
	.contacts__row {
		margin: 0; }
	.contacts__col {
		padding: 0 32px;
		margin-bottom: 64px; }
	.contacts__body {
		padding: 0 100px; } }
