.events-black {
	position: relative;
	padding: 32px 0;
	background-color: $base;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 500%;
		background-color: $base; }
	.events__top {
		.show-more-link {
			color: #fff;
			.icon {
				fill: #fff; } }
		.h2, h2 {
			color: #fff; } } }

.events-black__container {
	position: relative;
	> .show-more-link {
		color: #fff;
		.icon {
			fill: #fff; } }
	.events__info {
		background-color: #fff; } }

.events__top {
	margin-bottom: 32px;
	.h2, h2 {
		margin-bottom: 0; } }

.events__item {
	margin-bottom: 32px; }

.events__img {
	position: relative;
	display: block;
	min-height: 288px; }

.events__info {
	padding: 24px;
	background-color: $gray;
	a {
		text-decoration: none; }
	.h2, h2 {
		margin-bottom: 24px; }
	p {
		margin-bottom: 24px; } }

.events__time-wrapper {
	position: relative;
	display: inline-block;
	margin-bottom: 26px;
	font-size: 13px;
	font-family: $ttl;
	letter-spacing: .04em;
	text-transform: uppercase; }

.events__time {
	display: flex;
	padding-bottom: 12px; }

.events__time-from, .events__time-top {
	display: flex;
	align-items: center;
	white-space: nowrap;
	span {
		display: inline-block;
		margin-right: 4px; } }

.events__time-top {
	position: relative;
	padding-left: 40px;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 8px;
		margin: auto;
		width: 24px;
		height: 1px;
		background-color: $base; } }

.events__seat {
	display: flex;
	align-items: center;
	white-space: nowrap;
	padding-top: 12px;
	span {
		display: inline-block;
		margin-right: 8px; }
	&:before {
		content: '';
		@include posCenter;
		height: 1px;
		background-color: #d1d1d1; } }

@media only screen and (min-width: 640px) {
	.events__item {
		display: flex;
		flex-wrap: wrap; }
	.events__img {
		width: 50%;
		height: auto;
		order: 1; }
	.events__info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		width: 50%;
		.show-more-link {
			align-self: end; } } }

@media only screen and (min-width: 768px) {
	.events-black {
		.events__item {
			&:last-child {
				margin-bottom: 0; } } }
	.events__top {
		display: flex;
		justify-content: space-between;
		align-items: center; } }

@media only screen and (min-width: 992px) {
	.events__time-wrapper {
		display: flex;
		align-items: center; }
	.events__time {
		padding-bottom: 0; }
	.events__seat {
		position: relative;
		padding-top: 0;
		padding-left: 36px;
		&:before {
			left: 18px;
			right: auto;
			height: 10px;
			width: 1px; } } }

@media only screen and (min-width: 1200px) {
	.events__top {
		margin-bottom: 48px; }
	.events-black {
		padding: 64px 0; }
	.events__img {
		min-height: 461px; }
	.events__info {
		padding: 64px;
		.h2, h2 {
			margin-bottom: 32px; } }
	.events__time-wrapper {
		margin-bottom: 32px; } }

@media only screen and (min-width: 1400px) {
	.events__img {
		width: 60%; }
	.events__info {
		width: 40%; } }
