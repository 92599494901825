.preloader {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	background-color: $base;
	z-index: 20;
	transition: transform 1s ease .7s; }

.preloader__body {
	display: flex;
	align-items: center;
	opacity: 1;
	transform: translateY(0);
	transition: all .5s ease; }

.preloader__logo {
	position: relative;
	margin-right: 14px;
	opacity: 0;
	transform: translateY(-30px);
	transition: opacity 1.4s ease, transform 1s ease;
	max-width: 200px; }

.preloader__plus {
	opacity: 0;
	transition: opacity 1s ease 1s;
	max-width: 26px; }

.preloader_start {
	.preloader__logo {
		opacity: 1;
		transform: translateY(0); } }

.preloader_plus-start {
	.preloader__plus {
		opacity: 1;
		animation: opacity 1.4s ease 1.8s; } }

.preloader_end {
	transform: translateY(-100%);
	.preloader__body {
		opacity: 0;
		transform: translateY(30px); } }

@media only screen and (min-width: 768px) {
	.preloader__logo {
		max-width: 100%;
		margin-right: 26px; }
	.preloader__plus {
		max-width: 100%; } }

@keyframes opacity {
	0% {
		opacity: 1; }
	50% {
		opacity: 0; }
	100% {
		opacity: 1; } }
