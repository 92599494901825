.page-navigation {
	margin-bottom: 32px;
	text-align: center; }

.page-navigation__inner {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: $ttl; }

.modern-page-previous, .modern-page-next {
	display: inline-block;
	position: relative;
	width: 24px;
	height: 24px;
	margin: 0 2px;
	.icon {
		@include posCenter; } }

.page-navigation__link, .modern-page-dots, .modern-page-current {
	width: 34px;
	padding: 12px 0;
	display: inline-block;
	position: relative;
	margin: 0 2px;
	text-decoration: none;
	color: $base;
	&:before {
		content: '';
		position: absolute;
		width: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		border-bottom: 2px solid transparent;
		transition: all .3s ease; } }

.modern-page-current {
	&:before {
		width: 100%;
		border-bottom: 2px solid $gray; } }

@media only screen and (min-width: 1200px) {
	.page-navigation {
		margin-bottom: 64px; }
	.page-navigation__link, .modern-page-dots, .modern-page-current {
		width: 62px;
		padding: 22px 0;
		display: inline-block;
		margin: 0 4px;
		transition: all .3s ease; }
	.modern-page-next {
		margin-left: 18px;
		&:hover {
			.icon {
				animation: bounce-left 1s infinite; } } }
	.modern-page-previous {
		margin-right: 18px;
		&:hover {
			.icon {
				animation: bounce-right 1s infinite; } } }
	.page-navigation__link {
		&:hover {
			&:before {
				width: 100%;
				border-bottom: 2px solid $gray; } } } }
