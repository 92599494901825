.filter-links {
	margin-bottom: 32px;
	padding: 16px 0 4px;
	border-top: 1px solid $gray;
	border-bottom: 1px solid $gray; }

.filter-links__inner {
	overflow-x: auto;
	margin: 0 -16px; }

.filter-links__body {
	display: flex;
	padding: 0 16px;
	margin-bottom: 12px;
	font-family: $ttl;
	font-size: 13px; }

.filter-links__item {
	padding-right: 24px; }

.filter-links__link {
	display: inline-block;
	padding: 6px 16px;
	letter-spacing: .04em;
	color: $base;
	text-decoration: none;
	text-transform: uppercase;
	background-color: $gray;
	white-space: nowrap; }

.filter-links__link_active {
	background-color: $dark-gray;
	color: #fff; }

@media only screen and (min-width: 1200px) {
	.filter-links__inner {
		overflow-x: visible;
		margin: 0; }
	.filter-links__body {
		padding: 0;
		margin-bottom: 0; }
	.filter-links {
		margin-bottom: 64px;
		padding: 24px 0; }
	.filter-links__link {
		transition: color .2s ease-in, background-color .2s ease-in;
		&:hover {
			color: #fff;
			background-color: $dark-gray; } } }
