.article-bg {
	@extend .flex-wrp;
	position: relative;
	padding-top: 32px;
	margin: 0 -16px 32px;
	.text {
		ul {
			> li {
				&:before {
					background-color: #fff; } } } } }

.article-bg__color {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 500%; }

.article-bg__col {
	position: relative;
	width: 100%;
	padding: 0 16px;
	color: #fff;
	&.text {
		margin-bottom: 20px; }
	.btn {
		margin-top: 16px; } }

@media only screen and (min-width: 640px) {
	.article-bg {
		padding-top: 0;
		align-items: center; }
	.article-bg__col {
		width: 50%;
		&.text {
			padding-top: 32px;
			padding-bottom: 32px;
			margin-bottom: 0; } } }

@media only screen and (min-width: 1200px) {
	.article-bg {
		margin: 0 -32px 64px; }
	.article-bg__list {
		margin: 0 -32px; }
	.article-bg__col {
		padding: 0 32px; } }
