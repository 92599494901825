.reviews-wrapper {
	padding-bottom: 32px; }

.reviews {
	text-align: center; }

.reviews__text {
	margin-bottom: 24px; }

.reviews__avatar {
	display: inline-block;
	width: 70px;
	height: 70px;
	margin-bottom: 24px;
	img {
		border-radius: 50%; } }

.reviews__name {
	margin-bottom: 8px;
	font-size: 16px;
	font-family: $ttl; }

.reviews__button-next, .reviews__button-prev {
	background-color: $gray;
	&:after {
		color: $base; } }

@media only screen and (max-width: 767px) {
	.reviews__arrows {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin-top: 16px;
		justify-content: space-between; }
	.reviews__button-next, .reviews__button-prev {
		position: relative;
		margin: 0;
		&:after {
			color: $base; } } }

@media only screen and (min-width: 768px) {
	.reviews__item {
		padding-left: 64px;
		padding-right: 64px; } }

@media only screen and (min-width: 1200px) {
	.reviews-wrapper {
		padding-top: 64px;
		padding-bottom: 128px; }
	.reviews__text {
		margin-bottom: 32px;
		padding: 0 64px; }
	.reviews__button-next, .reviews__button-prev {
		background-color: #fff;
		transition: background-color .3s ease;
		&:hover {
			background-color: $gray; } }
	.reviews__avatar {
		margin-bottom: 32px; } }
