.elements {
	@extend .flex-wrp;
	margin: 0 -16px; }

.elements__col {
	width: 100%;
	padding: 0 16px;
	margin-bottom: 32px; }

.elements__img {
	display: block;
	margin-bottom: 16px; }

@media only screen and (min-width: 640px) {
	.elements__col {
		width: 50%; } }

@media only screen and (min-width: 992px) {
	.elements__col {
		width: 33.33333333%; } }

@media only screen and (min-width: 1200px) {
	.elements__col {
		margin-bottom: 64px; } }
