.nav__lang {
	display: flex; }

.lang {
	position: relative;
	padding: 12px;
	font-weight: 500;
	letter-spacing: .3px; }

.js-lang-all {
	width: 100%;
	padding: 10px;
	left: 0;
	top: 100%;
	background-color: #fff;
	position: absolute;
	transition: all .3s ease;
	opacity: 0;
	visibility: hidden;
	transform: translateY(30px);
	box-shadow: 0 10px 30px rgba(167, 167, 167, .25);
	z-index: 2;
	font-weight: 500;
	font-size: 16px;
	span, a {
		display: block;
		color: $base;
		padding: 9px 25px 9px 10px; }
	span {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			right: 10px;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 6px;
			height: 6px;
			background-color: $base;
			border-radius: 6px; } }
	a {
		text-decoration: none;
		transition: background-color .2s ease;
		&:hover {
			background-color: $gray; } } }

.js-lang-all_show {
	transform: translateY(0);
	opacity: 1;
	visibility: visible; }

.lang__selected {
	position: relative;
	display: flex;
	align-items: center;
	&:after {
		content: '';
		margin-left: 8px;
		border-top: 4px solid #fff;
		border-left: 3px solid transparent;
		border-right: 3px solid transparent; } }

.lang_country {
	.lang__inner {
		display: flex; } }
.lang__all {
	min-width: 80px; }
@media only screen and (max-width: 991px) {
	.nav__lang {
		border-top: 1px solid $gray;
		margin: 0 -16px;
		.lang {
			width: 50%;
			flex-grow: 1;
			padding: 16px;
			&:first-child {
				border-right: 1px solid $gray; } }
		.lang__selected {
			color: $base;
			&:after {
				border-top-color: $base; } } } }

@media only screen and (min-width: 992px) {

	.lang_country {
		.lang__all {
			min-width: 152px; } }
	.lang {
		margin-right: 12px; } }

@media only screen and (min-width: 1200px) {
	.lang {
		cursor: pointer; } }
