.before-after {
	margin-bottom: 32px; }

.before-after__pagination {
	margin-bottom: 13px;
	font-family: $ttl;
	font-size: 13px;
	left: 0;
	bottom: 100%; }

.before-after-text__info {
	max-width: 790px;
	margin: 0 auto 32px;
	text-align: center; }

.before-after-slider {
	margin-bottom: 13px; }

.before-after__text {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
	font-family: $ttl;
	font-size: 13px;
	letter-spacing: .04em; }

.before-after-slider__prev, .before-after-slider__next {
	display: none;
	background-color: $gray;
	&:after {
		color: $base; } }

.before-after-text__arrows {
	display: flex;
	justify-content: space-between; }

.before-after-text__prev, .before-after-text__next {
	position: relative;
	background-color: $gray;
	margin: 0;
	&:after {
		color: $base; } }

@media only screen and (min-width: 768px) {
	.before-after-slider__prev, .before-after-slider__next {
		display: flex; }
	.before-after-text__arrows {
		display: none; } }

@media only screen and (min-width: 1200px) {
	.before-after {
		margin-bottom: 64px;
		border-bottom: 1px solid #d1d1d1; }
	.before-after__pagination {
		margin-bottom: 24px; } }
