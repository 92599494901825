$red: #FF6C6C;
$green: #2CC886;
$base: #000;
$gray: #ececec;
$dark-gray: #323232;
$font: ('GraphikLCG', sans-serif, Arial, Helvetica);
$ttl: ('Stolzl', sans-serif, Arial, Helvetica);

@mixin posCenter() {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto; }

@mixin posLeftCenter() {
	position: absolute;
	left: 0;
	bottom: 0;
	top: 0;
	margin: auto; }

@mixin posRightCenter() {
	position: absolute;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto; }

@mixin placeholder($color) {
	&::-webkit-input-placeholder {
		color: $color;
		opacity: 1; }
	&:-moz-placeholder {
		color: $color;
		opacity: 1; }
	&::-moz-placeholder {
		color: $color;
		opacity: 1; }
	&:-ms-input-placeholder {
		color: $color;
		opacity: 1; } }

@mixin font($font_name, $file__name, $weight, $style) {
	@font-face {
		font-family: $font_name;
		font-display: swap;
		src: url('../fonts/' + $file__name + '.woff2') format('woff2');
		font-weight: $weight;
		font-style: $style; } }

@import "fonts";
@import "reset";
@import "swiper-bundle";
@import "jquery.fancybox";
@import "styles";
@import "text";
@import "popup-custom";
@import "sumoselect";
@import "form";
@import "marquee";
@import "components/header";
@import "components/lang";
@import "components/footer";
@import "components/menu";
@import "components/main-top";
@import "components/search-sections";
@import "components/search-result";
@import "components/slider";
@import "components/tabs";
@import "components/catalog";
@import "components/catalog-carousel";
@import "components/slider-logo";
@import "components/about-main";
@import "components/numbers";
@import "components/events";
@import "components/news";
@import "components/social";
@import "components/breadcrumb";
@import "components/content__aside";
@import "components/left-menu";
@import "components/page-navigation";
@import "components/top-news";
@import "components/filter-links";
@import "components/article";
@import "components/article-img";
@import "components/article-bg";
@import "components/reviews";
@import "components/topic";
@import "components/advantages";
@import "components/contacts";
@import "components/product";
@import "components/elements";
@import "components/before-after";
@import "components/downloads";
@import "components/toggle-info";
@import "components/preloader";
