.numbers {
	@extend .flex-wrp;
	padding: 0 0 32px; }

.numbers__col {
	width: 100%; }

.numbers__item {
	border-top: 1px solid #d1d1d1;
	padding: 32px 0 24px; }

.numbers__q {
	margin-bottom: 32px;
	font: 700 48px / 1.2 $ttl;
	letter-spacing: .01em; }

@media only screen and (min-width: 640px) {
	.numbers {
		margin: 0 -16px; }
	.numbers__col {
		width: 50%;
		padding: 0 16px; } }

@media only screen and (min-width: 1200px) {
	.numbers {
		margin: 0 -32px; }
	.numbers__col {
		width: 33.33333333%;
		padding: 0 32px; }
	.numbers_4 {
		.numbers__col {
			width: 25%; } }
	.numbers_2 {
		.numbers__col {
			width: 50%; } }
	.numbers_1 {
		.numbers__col {
			width: 100%; } }
	.numbers__item {
		padding-bottom: 64px; } }
