.my-form {
	width: 100%;
	max-width: 490px;
	margin: 0 auto;
	padding: 48px 0;
	.btn {
		width: 100%; } }

.my-form__ttl {
	text-align: center; }

.btn {
	display: inline-block;
	padding: 18px 14px;
	text-decoration: none;
	text-align: center;
	color: #fff;
	font-weight: 500;
	letter-spacing: .3px;
	background-color: $base;
	border: 1px solid $base;
	&:disabled {
		opacity: .5;
		cursor: default; } }

.btn_icon {
	span {
		position: relative;
		display: inline-block;
		padding-right: 32px; }
	.icon {
		@include posRightCenter;
		fill: #fff; } }

.btn_border-white {
	border-color: #fff;
	background-color: transparent;
	color: #fff; }

.btn_border-black {
	background-color: #fff;
	color: $base; }

.btn_white {
	background-color: #fff;
	border-color: #fff;
	color: $base;
	&.btn_icon {
		.icon {
			fill: $base; } } }

.btn_gray {
	background-color: #323232;
	border-color: #323232;
	color: #fff; }

.form-note {
	margin-bottom: 24px;
	text-align: center;
	font-size: 12px; }

.my-field__row {
	@extend .flex-wrp;
	margin: 0 -16px; }

.my-field__col {
	width: 100%;
	padding: 0 16px;
	.btn {
		width: 100%; } }

.my-field-wrapper {
	margin-bottom: 24px; }

.my-label {
	position: relative;
	display: block;
	margin-bottom: 5px;
	font-size: 15px;
	line-height: 1.4;
	sup {
		vertical-align: top;
		margin-left: 2px; } }

.my-field {
	height: 50px;
	width: 100%;
	padding: 0 16px;
	border: 1px solid #7b7b7b; }

.my-textarea {
	width: 100%;
	border: 1px solid #7b7b7b;
	height: 150px;
	resize: none;
	padding: 16px; }

.my-radio {
	position: absolute;
	left: 4px;
	opacity: 0;
	visibility: hidden;
	&:checked + .my-radio-label {
		&:after {
			content: '';
			position: absolute;
			left: 5px;
			top: 2px;
			width: 11px;
			height: 11px;
			background-color: $base; } } }

.my-radio-wrapper {
	position: relative;
	margin-bottom: 20px; }

.my-radio-label {
	position: relative;
	padding-left: 31px;
	cursor: pointer;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: -2px;
		width: 20px;
		height: 20px;
		background-color: #fff;
		border: 1px solid $base; } }

.my-checkbox-wrapper {
	@extend .my-radio-wrapper; }

.my-checkbox {
	position: absolute;
	left: 4px;
	opacity: 0;
	visibility: hidden;
	&:checked + .my-checkbox-label {
		&:after {
			content: '';
			position: absolute;
			width: 12px;
			height: 9px;
			left: 4px;
			top: 3px;
			background: center / cover no-repeat url('../images/tick.svg'); }
		&:before {
			background-color: $base;
			border-color: $base; } } }

.my-checkbox-label {
	position: relative;
	padding-left: 31px;
	cursor: pointer;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: -2px;
		width: 20px;
		height: 20px;
		background-color: #fff;
		border: 1px solid #7b7b7b; } }

.g-recaptcha {
	overflow: hidden;
	max-width: 100%; }

.g-recaptcha iframe, .g-recaptcha > div, .g-recaptcha body > div[style]:last-of-type {
	transform: scale(0.9);
	transform-origin: left top; }

.btn-center {
	text-align: center; }

.form-message {
	text-align: center;
	font-weight: 500;
	color: #fff;
	.icon {
		margin-right: 10px;
		fill: #fff;
		flex-shrink: 0; } }

.err_mess, .complete {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 36px;
	padding: 16px 10px; }

.err_mess {
	background-color: $red; }

.complete {
	background-color: $green; }

.err {
	border-color: $red; }

.my-form-big {
	max-width: 100%;
	.h2, h2 {
		margin-bottom: 32px;
		text-align: center; } }

.file-wrapper {
	width: 100%;
	display: flex;
	align-items: center; }

.file {
	@extend .my-field;
	display: inline-block;
	padding: 0 40px 0 16px;
	position: relative;
	cursor: pointer;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	line-height: 50px;
	.icon {
		position: absolute;
		right: 10px;
		top: 0;
		bottom: 0;
		margin: auto;
		fill: $base; } }

.file__info {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0; }

.inputfile {
	display: none; }

.captcha-row {
	display: flex;
	flex-wrap: wrap; }

.captcha-img {
	width: 100%;
	img {
		height: 50px; } }

.captcha-field {
	margin-bottom: 10px;
	width: 100%; }

@media only screen and (min-width: 640px) {
	.my-field__col {
		width: 50%; }
	.captcha-img {
		width: auto;
		flex-shrink: 0; }
	.captcha-field {
		padding-right: 15px; } }

@media only screen and (min-width: 1200px) {
	.my-form {
		padding: 64px 0; }
	.my-form-big {
		margin-bottom: 40px;
		.h2, h2 {
			margin-bottom: 56px; } }
	.btn {
		padding-left: 48px;
		padding-right: 48px;
		position: relative;
		transition: all .3s ease-in-out;
		span {
			position: relative; }
		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 0;
			transition: all .3s ease;
			pointer-events: none; }
		&:not(:disabled):hover {
			color: #000;
			&:before {
				width: 100%;
				background-color: #fff; }
			&.btn_border-white {
				color: $base; }
			&.btn_white {
				border-color: #fff;
				&:before {
					background-color: $gray; } }
			&.btn_gray {
				border-color: transparent; }
			&.btn_border-black {
				color: #fff;
				&:before {
					background-color: $base; } } } }
	.my-field {
		height: 50px; } }
