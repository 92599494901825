.my-select {
	opacity: 0;
	height: 50px;
	width: 200px; }

.selects > span {
	display: block;
	margin-bottom: 10px; }

.SumoSelect p {
	margin: 0; }

.SumoSelect {
	width: 100%;
	font-size: 14px;
	cursor: pointer; }

.sumoStopScroll {
	overflow: hidden; }

/* Filtering style */
.SumoSelect .hidden {
	display: none; }

.SumoSelect .search-txt {
	display: none;
	outline: none; }


.SumoSelect .no-match {
	display: none;
	padding: 6px; }

.SumoSelect.open .search-txt {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	margin: 0;
	padding: 0 20px;
	height: 38px;
	border: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 5px; }

/*.SumoSelect.open>.search>span, .SumoSelect.open>.search>label
	visibility: hidden*/
/*this is applied on that hidden select. DO NOT USE display:none or visiblity:hidden and Do not override any of these properties. */

.SelectClass, .SumoUnder {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	border: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0; }

.SelectClass {
	z-index: 1; }

.SumoSelect > .optWrapper > .options li.opt label, .SumoSelect > .CaptionCont, .SumoSelect .select-all > label {
	user-select: none; }

.SumoSelect {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	outline: none;
	cursor: pointer; }

.SumoSelect > .CaptionCont {
	position: relative;
	display: block;
	border: 1px solid #7b7b7b;
	min-height: 14px;
	height: 50px;
	margin: 0;
	background-color: #fff; }

.SumoSelect.open > .CaptionCont {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0; }

.SumoSelect > .CaptionCont > span {
	display: block;
	padding-right: 30px;
	padding-left: 16px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	line-height: 50px;
	color: $base;
	font-family: $font; }

/*placeholder style*/
.SumoSelect > .CaptionCont > span.placeholder {
	color: #ccc;
	font-style: italic; }

.SumoSelect > .CaptionCont > label {
	position: absolute;
	top: 0;
	right: 16px;
	bottom: 0;
	margin: auto 0;
	width: 12px;
	height: 7px;
	&:before {
		content: '';
		@include posCenter;
		width: 12px;
		height: 7px;
		border-top: 7px solid $base;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent; } }

.SumoSelect.open > .CaptionCont > label {
	transform: rotate(180deg); }

.SumoSelect > .CaptionCont > label > .icon {
	position: absolute;
	width: 12px;
	height: 7px;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	fill: #000; }

.SumoSelect > .optWrapper {
	display: none;
	z-index: 4;
	top: 30px;
	width: 100%;
	position: absolute;
	left: 0;
	box-sizing: border-box;
	background: #fff;
	border: 1px solid #7b7b7b;
	box-shadow: 0 10px 30px rgba(167, 167, 167, 0.25), inset 0 1px 0 rgba(158, 158, 158, 0.25);
	overflow: hidden; }

.SumoSelect.open > .optWrapper {
	top: 100%;
	display: block;
	margin-top: -1px; }

.SumoSelect.open > .optWrapper.up {
	top: auto;
	bottom: 100%;
	margin-bottom: 5px; }

.SumoSelect > .optWrapper ul {
	list-style: none;
	display: block;
	padding: 0;
	margin: 0;
	overflow: auto; }

.SumoSelect > .optWrapper > .options {
	position: relative;
	/*Set the height of pop up here (only for desktop mode)*/
	max-height: 250px;
	margin-bottom: 0; }
/*height*/

.SumoSelect > .optWrapper.okCancelInMulti > .options {
	border-radius: 2px 2px 0 0; }

.SumoSelect > .optWrapper.selall > .options {
	border-radius: 0 0 2px 2px; }

.SumoSelect > .optWrapper.selall.okCancelInMulti > .options {
	border-radius: 0; }

.SumoSelect > .optWrapper > .options li.group.disabled > label {
	opacity: 0.5; }

.SumoSelect > .optWrapper > .options li ul li.opt {
	padding-left: 22px; }

.SumoSelect > .optWrapper.multiple > .options li ul li.opt {
	padding-left: 50px; }

.SumoSelect > .optWrapper.isFloating > .options {
	max-height: 100%;
	box-shadow: 0 0 100px #595959; }

.SumoSelect > .optWrapper > .options li.opt {
	padding: 11px 16px;
	position: relative;
	color: $base;
	line-height: 18px;
	margin-bottom: 0;
	transition: background-color .3s ease-in; }

.SumoSelect > .optWrapper > .options li.opt:before {
	display: none; }

.SumoSelect > .optWrapper.selall > .options > li.opt:first-child {
	border-radius: 0; }

.SumoSelect > .optWrapper > .options > li.opt:last-child {
	border-bottom: none; }

.SumoSelect > .optWrapper.okCancelInMulti > .options > li.opt:last-child {
	border-radius: 0; }

.SumoSelect > .optWrapper > .options li.opt:hover {
	background-color: $gray; }

.SumoSelect > .optWrapper > .options li.opt.selected {
	background-color: $gray; }

.SumoSelect > .optWrapper > .options li.opt.sel, .SumoSelect .select-all.sel {
	background-color: #a1c0e4; }

.SumoSelect > .optWrapper > .options li label {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: block;
	cursor: pointer; }

.SumoSelect > .optWrapper > .options li span {
	display: none; }

.SumoSelect > .optWrapper > .options li.group > label {
	cursor: default;
	padding: 8px 6px;
	font-weight: bold; }

/*Floating styles*/
.SumoSelect > .optWrapper.isFloating {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 90%;
	bottom: 0;
	margin: auto;
	max-height: 90%; }

/*disabled state*/
.SumoSelect > .optWrapper > .options li.opt.disabled {
	background-color: inherit;
	pointer-events: none; }

.SumoSelect > .optWrapper > .options li.opt.disabled * {
	opacity: 0.5; }

/*styling for multiple select*/
.SumoSelect > .optWrapper.multiple > .options li.opt {
	padding-left: 35px;
	cursor: pointer; }

.SumoSelect > .optWrapper.multiple > .options li.opt span, .SumoSelect .select-all > span {
	position: absolute;
	display: block;
	width: 30px;
	top: 0;
	bottom: 0;
	margin-left: -35px; }

.SumoSelect > .optWrapper.multiple > .options li.opt span i, .SumoSelect .select-all > span i {
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 14px;
	height: 14px;
	border: 1px solid #AEAEAE;
	border-radius: 2px;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
	background-color: #fff; }

.SumoSelect > .optWrapper > .MultiControls {
	display: none;
	border-top: 1px solid #ddd;
	background-color: #fff;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
	border-radius: 0 0 3px 3px; }

.SumoSelect > .optWrapper.multiple.isFloating > .MultiControls {
	display: block;
	margin-top: 5px;
	position: absolute;
	bottom: 0;
	width: 100%; }

.SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls {
	display: block; }

.SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls > p {
	padding: 6px; }

.SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls > p:focus {
	box-shadow: 0 0 2px #a1c0e4;
	border-color: #a1c0e4;
	outline: none;
	background-color: #a1c0e4; }

.SumoSelect > .optWrapper.multiple > .MultiControls > p {
	display: inline-block;
	cursor: pointer;
	padding: 12px;
	width: 50%;
	box-sizing: border-box;
	text-align: center; }

.SumoSelect > .optWrapper.multiple > .MultiControls > p:hover {
	background-color: #f1f1f1; }

.SumoSelect > .optWrapper.multiple > .MultiControls > p.btnOk {
	border-right: 1px solid #DBDBDB;
	border-radius: 0 0 0 3px; }

.SumoSelect > .optWrapper.multiple > .MultiControls > p.btnCancel {
	border-radius: 0 0 3px 0; }

/*styling for select on popup mode*/
.SumoSelect > .optWrapper.isFloating > .options li.opt {
	padding: 12px 6px; }

/*styling for only multiple select on popup mode*/
.SumoSelect > .optWrapper.multiple.isFloating > .options li.opt {
	padding-left: 35px; }

.SumoSelect > .optWrapper.multiple.isFloating {
	padding-bottom: 43px; }

.SumoSelect > .optWrapper.multiple > .options li.opt.selected span i, .SumoSelect .select-all.selected > span i, .SumoSelect .select-all.partial > span i {
	background-color: rgb(17, 169, 17);
	box-shadow: none;
	border-color: transparent;
	background-image: url('data:image/pngbase64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC');
	background-repeat: no-repeat;
	background-position: center center; }

/*disabled state*/
.SumoSelect.disabled {
	opacity: 0.7;
	cursor: not-allowed; }

.SumoSelect.disabled > .CaptionCont {
	border-color: #ccc;
	box-shadow: none; }

/**Select all button**/
.SumoSelect .select-all {
	border-radius: 3px 3px 0 0;
	position: relative;
	border-bottom: 1px solid #ddd;
	background-color: #fff;
	padding: 10px 0 10px 35px;
	cursor: pointer; }

.SumoSelect .select-all > label, .SumoSelect .select-all > span i {
	cursor: pointer; }

.SumoSelect .select-all.partial > span i {
	background-color: #ccc; }

/*styling for optgroups*/
.SumoSelect > .optWrapper > .options li.optGroup {
	padding-left: 5px;
	text-decoration: underline; }

.SumoSelect {
	text-align: left; }
