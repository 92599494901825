.header {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 12;
	color: #fff;
	transition: background-color .2s ease; }

.header__logo {
	display: inline-block;
	img {
		width: 180px;
		height: 20px;
		display: block; } }

.header__top-inner {
	padding: 7px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid rgba(255, 255, 255, .2); }

.search-btn {
	display: flex;
	padding: 14px 12px 14px 14px;
	align-items: center;
	text-decoration: none;
	font-weight: 500;
	letter-spacing: .3px;
	color: #fff;
	.icon {
		fill: #fff;
		margin-right: 8px; } }

.header__phones {
	font-weight: 500;
	a {
		text-decoration: none;
		color: #fff; } }

.header__right {
	font-weight: 500; }

.header_fixed {
	position: fixed;
	background-color: $base; }

@media only screen and (max-width: 1199px) {
	.header__right {
		.btn {
			display: none; } }
	.nav-shadow {
		display: none;
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 10; }
	.nav-shadow_active {
		display: block; }

	.nav {
		display: flex;
		flex-direction: column;
		position: fixed;
		padding: 65px 16px 16px;
		width: 100%;
		max-width: 450px;
		height: 100%;
		right: -100%;
		top: 0;
		overflow-y: auto;
		transition: right .4s ease;
		z-index: 11;
		background-color: #fff;
		.btn {
			width: 100%;
			background-color: $base;
			border-color: $base;
			color: #fff; } }
	.nav_open {
		right: 0; } }

@media only screen and (max-width: 991px) {
	.header__right {
		.header__phones, .lang, .btn {
			display: none; } }
	.nav {
		.header__phones {
			opacity: 0;
			position: absolute;
			display: flex;
			justify-content: center;
			flex-direction: column;
			top: 0;
			left: 16px;
			height: 65px; }
		&.nav_open {
			.header__phones {
				opacity: 1;
				transition: opacity .3s ease .4s; } }
		.search-btn {
			color: $base;
			padding: 13px 16px;
			margin: 0 -16px;
			border-top: 1px solid $gray;
			border-bottom: 1px solid $gray;
			.icon {
				fill: $base; } } }
	.header__phones {
		a {
			color: #000; } }
	.header__right {
		.search-btn {
			display: none; } } }

@media only screen and (min-width: 992px) {
	.header__phones {
		margin-right: 24px;
		text-align: right;
		a {
			display: inline-block; } }
	.header__right {
		display: flex;
		align-items: center; }
	.search-btn {
		margin-right: 12px; } }


@media only screen and (min-width: 1200px) {
	.header__top-inner {
		padding: 0;
		.btn {
			padding: 12px 24px; } }
	.search-btn {
		margin-right: 24px;
		padding-top: 36px;
		padding-bottom: 36px; }
	.header__logo {
		img {
			width: 215px;
			height: 24px; } }
	.header__top-inner {
		border-bottom: none; } }
