.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-bottom: 24px;
	font-size: 15px;
	opacity: .7;
	color: #fff;
	a {
		display: inline-block;
		text-decoration: none;
		color: #fff; }
	.icon {
		margin-left: 12px;
		fill: #fff; } }

.breadcrumb__item {
	display: flex;
	align-items: center;
	margin-right: 12px;
	margin-bottom: 4px; }

@media only screen and (min-width: 1200px) {
	.breadcrumb {
		a {
			position: relative;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: -4px;
				width: 0;
				border-bottom: 1px solid transparent;
				transition: all .3s ease; }
			&:hover {
				&:before {
					width: 100%;
					border-bottom-color: #fff; } } } } }
