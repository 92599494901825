.topic {
	.text {
		margin-bottom: 0;
		&:last-child {
			margin-bottom: 0; } } }

.topic__item {
	padding: 32px 0;
	border-bottom: 1px solid rgba(0, 0, 0, .1);
	&:first-child {
		border-top: 1px solid rgba(0, 0, 0, .1); }
	& > *:last-child {
		margin-bottom: 0; } }

.topic__top-info {
	margin-bottom: 32px; }

.topic__time {
	display: flex;
	align-items: center;
	font-family: $ttl;
	font-size: 13px;
	letter-spacing: .04em; }

.topic__time-to {
	position: relative;
	padding-left: 62px;
	&::before {
		content: '';
		position: absolute;
		width: 46px;
		height: 1px;
		left: 8px;
		top: 0;
		bottom: 0;
		margin: auto;
		background-color: #0e0e0e; } }

.topic__speaker {
	@extend .flex-wrp;
	margin-bottom: 32px; }

.topic__speaker-ava {
	margin-bottom: 32px;
	margin-right: 32px;
	width: 137px;
	height: 137px;
	border-radius: 50%;
	flex-shrink: 0; }

.topic__speaker-name {
	margin-bottom: 8px;
	font-family: $ttl; }

@media only screen and (min-width: 640px) {
	.topic__speaker-ava {
		margin-bottom: 0; }
	.topic__speaker {
		flex-wrap: nowrap;
		align-items: center; } }
