.menu__link-wrp {
	padding-right: 20px;
	.icon {
		display: none; } }

.menu__link {
	font-size: 18px;
	font-family: $ttl;
	text-decoration: none; }

.submenu {
	display: none;
	font-weight: 500;
	> .submenu {
		font-weight: 400; } }

.submenu_catalog .submenu {
	.submenu__link {
		font-family: $font;
		font-weight: 500; } }

.submenu__link {
	text-decoration: none;
	font-family: $ttl; }

.js-toggle-menu {
	display: none; }

@media only screen and (max-width: 1199px) {
	.js-toggle-menu {
		position: relative;
		display: flex;
		height: 50px;
		align-items: center;
		font-size: 16px;
		font-weight: 500;
		z-index: 12;
		transition: color .3s ease;
		margin-left: 12px; }
	.toggle-menu {
		display: block;
		position: relative;
		width: 20px;
		height: 14px;
		margin-left: 8px;
		cursor: pointer;
		.toggle-menu_center, &:before, &:after {
			display: block;
			position: absolute;
			width: 20px;
			height: 2px;
			left: 0;
			background-color: #fff;
			border: none;
			transition: transform 0.5s, background-color 0.2s; }
		&:before, &:after {
			content: ''; }
		&:before {
			top: 0; }
		&:after {
			top: 12px; }
		.toggle-menu_center {
			top: 6px; } }
	.js-toggle-menu_active {
		color: $base;
		.toggle-menu {
			&:before, &:after {
				background-color: $base;
				top: 6px; }
			&:before {
				-webkit-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				transform: rotate(-45deg); }
			&:after {
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg); } }
		.toggle-menu_center {
			background-color: $base;
			opacity: 0;
			bottom: 0;
			-webkit-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			transform: rotate(180deg); } }
	.submenu__link-all-wrp {
		display: none; }
	.menu {
		flex: 1;
		position: relative;
		overflow-y: auto;
		z-index: 1;
		margin: 0 -16px 10px; }
	.menu__item {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			border-bottom: 1px solid $gray;
			left: 16px;
			right: 16px;
			bottom: 0; }
		&:first-child {
			border-top: none; } }
	.menu__link-wrp {
		position: relative;
		padding: 11px 40px 11px 16px;
		.icon {
			display: block;
			position: absolute;
			right: 16px;
			bottom: 0;
			top: 0;
			margin: auto;
			width: 16px;
			height: 16px; } }
	.show-submenu {
		.icon {
			transform: rotate(180deg); } }
	.submenu__link-wrp {
		position: relative;
		padding: 14px 40px 14px 16px;
		.icon {
			display: block;
			position: absolute;
			right: 16px;
			bottom: 0;
			top: 0;
			margin: auto; } }
	.submenu__item {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 16px;
			right: 16px;
			border-top: 1px solid $gray; } }
	.submenu .submenu {
		position: relative;
		background-color: #f3f3f3; } }

@media only screen and (min-width: 1200px) {
	.menu {
		position: relative;
		display: flex;
		align-items: center;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			border-bottom: 1px solid rgba(255, 255, 255, .2); } }
	.menu__link-wrp {
		display: flex;
		align-items: center;
		padding-right: 0; }
	.menu__item {
		display: flex;
		justify-content: center;
		flex-basis: auto;
		padding: 0 16px;
		&:hover {
			.submenu {
				opacity: 1;
				visibility: visible;
				transform: none; } }
		&:not(:first-child) {
			position: relative; }
		&:first-child {
			padding-left: 0; } }
	.menu__link {
		position: relative;
		display: flex;
		align-items: center;
		font-size: 16px;
		font-family: $font;
		font-weight: 500;
		padding: 18px 0;
		letter-spacing: .3px;
		color: #fff;
		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0;
			height: 3px;
			margin: auto;
			border-bottom: 3px solid #fff;
			transition: all .4s ease;
			opacity: 0; }
		&:hover {
			&:before {
				opacity: 1;
				width: 100%; } } }
	.menu__link_has-sub {
		&:after {
			content: '';
			margin-left: 8px;
			border-top: 4px solid #fff;
			border-left: 3px solid transparent;
			border-right: 3px solid transparent; } }
	.submenu__link {
		position: relative;
		display: block;
		color: $base;
		padding: 12px 5px;
		span {
			position: relative; }
		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 0;
			pointer-events: none;
			background-color: transparent;
			transition: all .4s ease-in-out; }
		&:hover {
			&:before {
				width: 100%;
				background-color: $gray; } } }
	.submenu__link_active {
		&:before {
			width: 100%;
			background-color: $gray; } }
	.submenu {
		display: block;
		position: absolute;
		top: 100%;
		left: 0;
		padding: 20px 0;
		opacity: 0;
		visibility: hidden;
		background-color: #fff;
		box-shadow: 0 15px 25px rgba(0, 0, 0, 0.05);
		transition: all .3s ease;
		transform: scaleY(0) translate3d(0, 0, 0);
		transform-origin: center top; }
	.submenu_base {
		padding: 64px;
		min-width: 480px;
		font-size: 20px;
		left: -16px;
		.submenu__col {
			padding: 0; }
		.submenu__item {
			border-bottom: 1px solid $gray; }
		.submenu__link {
			padding: 17px 5px; } }
	.submenu_catalog {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		padding: 64px 48px 0;
		.submenu {
			padding: 24px 0;
			box-shadow: none;
			position: relative;
			display: flex;
			flex-wrap: wrap;
			top: 0;
			height: 100%;
			border-top: 1px solid $gray;
			border-bottom: 1px solid $gray;
			.submenu__col {
				width: 50%;
				flex-grow: 1;
				padding-left: 0; }
			.submenu__link-wrp {
				margin-bottom: 0;
				border-bottom: none;
				font-size: 16px; } }
		> .submenu__col {
			width: 25%;
			margin-bottom: 64px;
			&:nth-child(3n+1) {
				width: 50%; }
			> .submenu__item {
				height: 100%;
				display: flex;
				flex-direction: column; } }
		.submenu__link-wrp {
			font-size: 20px; } }
	.submenu__link-wrp {
		.icon {
			display: none; } }
	.submenu__col {
		padding: 0 16px; }
	.submenu__link-all-wrp {
		padding-top: 24px; }
	.submenu__link-all {
		position: relative;
		display: inline-block;
		padding-right: 8px;
		text-decoration: none;
		color: $base;
		font-weight: 500;
		.icon {
			position: absolute;
			left: 100%;
			top: 0;
			bottom: 0;
			margin: auto; } } }
