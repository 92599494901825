.fancybox-slide--html {
	padding: 0; }

.fancybox-slide {
	overflow-x: hidden; }

.popup {
	position: relative;
	width: 100%;
	max-width: 532px;
	background: none;
	padding: 0 16px;
	.btn {
		width: 100%; }
	.text {
		&:last-child {
			margin-bottom: 0; } } }

.fancybox-slide_info {
	text-align: right; }

#info {
	width: 100%;
	max-width: 1640px;
	padding: 0;
	text-align: right;
	overflow: hidden;
	left: 100%;
	transition: left .3s ease-in;
	&.info_active {
		left: 0; } }

.info__body {
	position: relative;
	text-align: left;
	.popup__inner {
		padding: 50px 16px; } }

.popup_hide {
	display: none; }

.popup__inner {
	position: relative;
	padding: 64px 16px 40px;
	background-color: #fff;
	> .text-s {
		text-align: center;
		margin-bottom: 32px; } }

#search-popup {
	max-width: 1468px;
	vertical-align: top;
	padding: 0;
	.popup__inner {
		padding: 0 16px; }
	.close-popup {
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto; } }

.search-popup__btn {
	@include posLeftCenter;
	width: 24px;
	height: 24px;
	background-color: transparent;
	border: none;
	padding: 0; }

.search-popup__field-wrapper {
	position: relative;
	border-bottom: 1px solid #d1d1d1; }

.search-popup__field {
	width: 100%;
	height: 56px;
	padding: 0 24px 0 40px;
	border: none;
	font-family: $ttl; }

.popup__ttl {
	font-family: $ttl;
	text-align: center;
	line-height: 1.3;
	margin-bottom: 24px; }

.close-popup {
	position: absolute;
	right: 16px;
	top: 16px;
	width: 24px;
	height: 24px;
	border: none;
	padding: 0;
	background-color: transparent;
	transition: color .3s ease;
	.icon {
		@include posCenter();
		fill: #000;
		transition: fill .3s ease; }
	&:hover {
		color: $red;
		.icon {
			fill: $red; } } }

.close-popup_text {
	width: auto;
	display: flex;
	align-items: center;
	font-weight: 500;
	letter-spacing: .3px;
	margin-bottom: 50px;
	.icon {
		position: relative;
		margin-right: 8px; } }

.text-wrapper {
	.close-popup_text {
		position: relative;
		right: 0;
		top: 0; } }

@media only screen and (min-width: 768px) {
	.fancybox-slide_info {
		padding-left: 64px; } }

@media only screen and (min-width: 1200px) {
	.fancybox-slide_info {
		padding-left: 107px; }
	#search-popup {
		padding: 16px 64px; }
	.close-popup {
		top: 24px;
		right: 24px; }
	.popup__ttl {
		margin-bottom: 36px; }
	.popup__inner {
		padding: 72px 48px 48px; }
	#search-popup {
		.popup__inner {
			padding: 0 32px; } }
	.search-popup__field {
		height: 88px; }
	.close-popup_text {
		margin-bottom: 66px; }
	.info__body {
		.popup__inner {
			padding-top: 118px;
			padding-bottom: 100px; } } }
