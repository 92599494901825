.article-img {
	.text:last-child {
		margin-bottom: 0; } }

.article-img__col {
	@extend .flex-wrp;
	margin: 0 -16px 32px; }

.article-img__media {
	width: 100%;
	padding: 0 16px;
	margin-bottom: 32px;
	picture {
		position: relative; } }

.article-img__text {
	width: 100%;
	padding: 0 16px; }

@media only screen and (min-width: 640px) {
	.article-img__col {
		&:nth-child(2n) {
			.article-img__media {
				order: 1; } } }
	.article-img__media {
		width: 50%;
		margin-bottom: 0; }
	.article-img__text {
		width: 50%; } }

@media only screen and (min-width: 768px) {
	.article-img__col {
		align-items: center; } }

@media only screen and (min-width: 1200px) {
	.article-img__col {
		margin: 0 -32px 64px; }
	.article-img__media {
		padding: 0 32px; }
	.article-img__text {
		padding: 0 32px; } }
