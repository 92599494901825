.main-wrapper {
	overflow: hidden; }

.main_black {
	background-color: #000;
	color: #fff; }

.main__container {
	width: 100%;
	max-width: 1468px;
	margin: 0 auto;
	padding: 0 16px; }

.main__row {
	margin: 0 -16px; }

.m-bottom {
	margin-bottom: 0 !important; }

.clearfix {
	*zoom: 1;
	&:before,
	&:after {
		display: table;
		content: "";
		line-height: 0; }
	&:after {
		clear: both; } }

.flex-wrp {
	display: flex;
	flex-wrap: wrap; }

.hide {
	display: none !important; }

.show {
	display: block !important; }

.icon {
	fill: #000;
	display: inline-block; }

.icon_16 {
	width: 16px;
	height: 16px; }

.icon_24 {
	width: 24px;
	height: 24px; }

.object-fit {
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover; }

.show-more-link {
	display: inline-block;
	position: relative;
	padding-right: 32px;
	text-decoration: none;
	font-weight: 500;
	letter-spacing: .3px;
	white-space: nowrap;
	.icon {
		@include posRightCenter; } }

.show-more-link_tablet {
	display: none; }

.video {
	display: inline-block;
	position: relative;
	&:before {
		content: '';
		@include posCenter;
		width: 96px;
		height: 96px;
		transition: transform .3s ease;
		background: url('../images/icon_video.svg') center no-repeat;
		z-index: 1;
		pointer-events: none; } }

.video_bg {
	display: block;
	min-height: 280px;
	margin-bottom: 32px; }

.picture {
	position: relative;
	@extend .video_bg; }

#map {
	position: relative;
	min-height: 368px;
	margin-bottom: 32px; }

.content__row {
	margin: 0 -16px; }

.content__body {
	padding: 0 16px; }

.share {
	display: inline-block;
	position: relative;
	padding-bottom: 10px;
	z-index: 5; }

.share__list {
	position: absolute;
	min-width: 230px;
	left: 0;
	top: 100%;
	padding: 10px;
	background-color: #fff;
	box-shadow: 0 10px 30px rgba(167, 167, 167, .25);
	transition: all .3s ease;
	opacity: 0;
	visibility: hidden;
	transform: translateY(30px); }

.share__list_show {
	opacity: 1;
	visibility: visible;
	transform: translateY(0); }

.share__link {
	display: block;
	position: relative;
	padding: 9px 9px 9px 40px;
	text-decoration: none;
	.icon {
		@include posLeftCenter; } }

.share-btn {
	display: inline-block;
	position: relative;
	padding-left: 32px;
	text-decoration: none;
	font-weight: 500;
	letter-spacing: .3px;
	white-space: nowrap;
	background-color: transparent;
	border: none;
	color: #fff;
	.icon {
		@include posLeftCenter;
		fill: #fff; } }

.other-products {
	margin-bottom: 32px; }

.other-products__top {
	@extend .news-main__top; }

.js-hide-text {
	display: none; }

.js-show-hide-link {
	position: relative;
	display: inline-block;
	margin-top: 12px;
	padding-right: 28px;
	font-weight: 500;
	letter-spacing: .3px;
	text-decoration: none;
	.icon {
		@include posRightCenter;
		fill: $base; } }

.js-show-hide-link_active {
	.icon {
		transform: rotate(180deg); } }

.tag {
	display: inline-block;
	padding: 7px 16px;
	background-color: $base;
	color: #fff;
	font-size: 13px;
	font-family: $ttl;
	text-align: center;
	letter-spacing: .04em; }

@keyframes bounce-left {
	0% {
		transform: translateX(0); }
	50% {
		transform: translateX(6px); } }

@keyframes bounce-right {
	0% {
		transform: translateX(0); }
	50% {
		transform: translateX(-6px); } }

@media only screen and (min-width: 768px) {
	.show-more-link_mobile {
		display: none; }
	.show-more-link_tablet {
		display: inline-block; }
	.video_bg {
		min-height: 486px; }
	#map {
		min-height: 568px; } }

@media only screen and (min-width: 992px) {
	.content__row {
		display: flex; }
	.content__body {
		flex-grow: 1;
		width: 70%; } }

@media only screen and (min-width: 1200px) {
	#map {
		margin-bottom: 64px; }
	.video_bg {
		margin-bottom: 64px; }
	.video {
		&:hover {
			&:before {
				transform: scale(1.1); } } }
	.bounce-arrow {
		.icon {
			transition: transform 1.3s ease; }
		&:hover {
			.icon {
				animation: bounce-left 1s infinite; } } }
	.zoom-img {
		position: relative;
		overflow: hidden;
		img {
			transition: transform .3s ease-in-out; }
		&:hover {
			img {
				transform: scale(1.1); } } }
	.main__container {
		padding: 0 64px; }
	.main__row {
		margin: 0 -64px; }
	.share__link {
		transition: background-color .3s ease;
		&:hover {
			background-color: $gray; } }
	.other-products {
		margin-bottom: 64px; } }

.swiper-button-next:after, .swiper-button-prev:after {
	display: none; }

@media only screen and (min-width: 1300px) {
	.content__body {
		width: 75%; } }

@media only screen and (min-width: 1440px) {
	.content__body {
		width: 76%; } }
