.article {
	margin-bottom: 32px; }

.article__list {
	@extend .flex-wrp;
	margin: 0 -16px; }

.article__col {
	width: 100%;
	padding: 0 16px;
	.text {
		margin-bottom: 0; } }

@media only screen and (min-width: 640px) {
	.article__col {
		width: 50%; } }

@media only screen and (min-width: 1200px) {
	.article {
		margin-bottom: 64px; }
	.article__list {
		margin: 0 -32px; }
	.article__col {
		padding: 0 32px; } }
