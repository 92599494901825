.about-main__media {
	margin-bottom: 48px;
	text-align: center; }

.about-main {
	.text:last-child {
		margin-bottom: 0; } }

.about-main__info {
	h2, .h2 {
		line-height: 1;
		margin-bottom: 24px; }
	p {
		font-size: 18px; } }


.about-main__col {
	padding: 32px 0; }

@media only screen and (min-width: 640px) {
	.about-main__col {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		&:nth-child(2n+1) {
			.about-main__media {
				order: 1; } } }
	.about-main__info, .about-main__media {
		width: 50%;
		padding: 0 10px;
		flex-grow: 1; }
	.about-main__media {
		margin-bottom: 0; } }

@media only screen and (min-width: 768px) {
	.about-main__col {
		align-items: center; } }

@media only screen and (min-width: 1200px) {
	.about-main {
		.numbers {
			margin: 0;
			padding: 32px 32px; } }
	.about-main__info {
		.h2, h2 {
			margin-bottom: 32px; } }
	.about-main__col {
		margin-left: 0;
		margin-right: 0;
		padding: 64px 0; }
	.about-main__info, .about-main__media {
		padding: 0 64px; } }
