.search-result {
	overflow: hidden; }

.search-result__body {
	width: 100%;
	max-width: 672px;
	margin: 0 auto;
	.btn {
		width: 100%; }
	.btn-center {
		margin-bottom: 48px; } }

.search-result__ttl {
	padding: 40px 0 48px;
	margin-bottom: 0;
	text-align: center; }

.search-result__item {
	margin-bottom: 35px; }

.search-result__img {
	display: inline-block;
	margin-bottom: 32px;
	flex-shrink: 0;
	max-width: 144px; }

.search-result__section-name {
	display: inline-block;
	margin-bottom: 16px;
	padding: 6px 16px;
	text-decoration: none;
	text-transform: uppercase;
	color: $base;
	font-family: $ttl;
	font-size: 13px;
	letter-spacing: .04em;
	background-color: $gray; }

.search-result__info {
	p {
		margin-bottom: 22px;
		font-size: 15px;
		line-height: 1.4; }
	h4, .h4 {
		a {
			text-decoration: none;
			color: $base; } } }

@media only screen and (min-width: 640px) {
	.search-result__item {
		display: flex; }
	.search-result__img {
		margin-bottom: 0;
		margin-right: 32px; }
	.search-result__info {
		flex-grow: 1; } }

@media only screen and (min-width: 768px) {
	.search-result__img {
		max-width: 215px; } }

@media only screen and (min-width: 1200px) {
	.btn-center {
		margin-bottom: 64px; }
	.search-result__ttl {
		padding: 64px 0; } }
