.downloads {
	@extend .flex-wrp;
	margin: 0 -16px 48px; }

.downloads__col {
	width: 100%;
	padding: 0 16px; }

.downloads__item {
	display: block;
	border-bottom: 1px solid #d1d1d1;
	position: relative;
	padding: 16px 26px 16px 0;
	font-weight: 500;
	text-decoration: none;
	.icon {
		@include posRightCenter; } }

@media only screen and (min-width: 640px) {
	.downloads__col {
		width: 50%; } }

@media only screen and (min-width: 1200px) {
	.downloads {
		margin-bottom: 96px; }
	.downloads__item {
		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -1px;
			width: 0;
			border-bottom: 1px solid $base;
			transition: width .3s ease; }
		&:hover {
			&:before {
				width: 100%; } } } }
