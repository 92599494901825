.news-main {
	padding: 32px 0;
	overflow: hidden; }

.news-main__top {
	@extend .events__top; }

.news {
	@extend .flex-wrp;
	margin: 0 -16px;
	h4, .h4 {
		margin-bottom: 12px; }
	a {
		text-decoration: none; } }

.news__col {
	width: 100%;
	padding: 0 16px;
	margin-bottom: 32px; }

.news__img {
	display: block;
	text-align: center;
	margin-bottom: 16px; }

.news__date {
	display: inline-block;
	margin-bottom: 16px;
	color: #585858;
	text-transform: uppercase;
	font-size: 13px;
	font-family: $ttl; }

@media only screen and (min-width: 640px) {
	.news__col {
		width: 50%; } }

@media only screen and (min-width: 768px) {
	.news-main {
		padding-bottom: 0; }
	.news__col {
		width: 33.33333333%; } }

@media only screen and (min-width: 1200px) {
	.news-main {
		padding-top: 64px; }
	.news__col {
		margin-bottom: 64px; } }
