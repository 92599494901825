.product {
	> .article-bg:first-child {
		margin-top: -32px; } }

.js-quantity {
	display: flex;
	align-items: center; }

.js-quantity__minus, .js-quantity__plus {
	position: relative;
	width: 50px;
	height: 50px;
	background-color: $gray;
	cursor: pointer;
	&:before {
		content: '';
		@include posCenter;
		width: 14px;
		height: 2px;
		background-color: $base; } }

.js-quantity__plus {
	&:after {
		content: '';
		@include posCenter;
		width: 2px;
		height: 14px;
		background-color: $base; } }

.js-quantity__field {
	width: 50px;
	height: 50px;
	padding: 0 5px;
	border: none;
	text-align: center;
	font-size: 16px;
	font-family: $ttl; }

.product-char {
	margin-bottom: 32px; }

.product-char__item {
	position: relative;
	padding-left: 38px;
	margin-bottom: 16px;
	&:last-child {
		margin-bottom: 0; }
	.icon {
		@include posLeftCenter; } }

@media only screen and (min-width: 1200px) {
	.product {
		> .article-bg:first-child {
			margin-top: -64px; } }
	.js-quantity__minus, .js-quantity__plus {
		transition: background-color .3s ease;
		&:before, &:after {
			transition: background-color .3s ease; }
		&:hover {
			background-color: $base;
			&:before, &:after {
				background-color: #fff; } } } }
