.toggle-info {
	margin-bottom: 34px;
	font-size: 15px;
	line-height: 1.4; }

.toggle-info__main {
	@extend .flex-wrp;
	align-items: center; }

.toggle-info__item {
	position: relative;
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 12px;
	margin-right: 11px;
	&:before {
		content: '';
		@include posLeftCenter;
		width: 9px;
		height: 9px;
		background-color: #c4c4c4;
		border-radius: 50%; } }

@media only screen and (min-width: 1200px) {
	.toggle-info {
		margin-bottom: 64px; } }
