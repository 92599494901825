.advantages {
	@extend .flex-wrp;
	margin: 0 -16px; }

.advantages__col {
	width: 100%;
	padding: 0 16px;
	margin-bottom: 32px; }

.advantages__item {
	padding-top: 32px;
	border-top: 1px solid #d1d1d1; }

.advantages__img {
	margin-bottom: 24px;
	border-radius: 50%; }

@media only screen and (min-width: 640px) {
	.advantages__col {
		width: 50%; } }

@media only screen and (min-width: 1200px) {
	.advantages {
		margin: 0 -32px; }
	.advantages__col {
		width: 33.33333333%;
		padding: 0 32px;
		margin-bottom: 64px; }
	.advantages_4 {
		.advantages__col {
			width: 25%; } }
	.advantages_2 {
		.advantages__col {
			width: 50%; } }
	.advantages_1 {
		.advantages__col {
			width: 100%; } } }
