.content__aside {
	padding: 0 16px;
	margin-bottom: 32px; }

.toggle-left-menu {
	margin-bottom: 15px;
	text-transform: uppercase;
	font-size: 13px;
	font-family: $ttl;
	letter-spacing: .04em; }

.left-nav {
	display: none; }

.left-nav__ttl {
	display: inline-block;
	position: relative;
	padding: 24px 0 24px 36px;
	line-height: 1.3;
	letter-spacing: .3px;
	font-weight: 500;
	text-decoration: none;
	.icon {
		@include posLeftCenter; } }

.left-nav__selected {
	padding: 16px 0;
	border-top: 1px solid rgba(0, 0, 0, .1);
	border-bottom: 1px solid rgba(0, 0, 0, .1); }

.left-nav__selected-body {
	display: inline-block;
	padding: 6px 16px;
	background-color: $gray;
	text-transform: uppercase;
	font-size: 13px;
	letter-spacing: .04em; }

@media only screen and (max-width: 991px) {
	.content__aside {
		margin-top: -32px; }
	.toggle-left-menu {
		position: relative;
		display: flex;
		align-items: center;
		padding: 21px 0;
		margin-bottom: 0; }
	.toggle-left-menu__btn {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 20px;
		height: 14px;
		margin: auto;
		cursor: pointer;
		.toggle-left-menu__btn-center, &:before, &:after {
			display: block;
			position: absolute;
			width: 20px;
			height: 2px;
			left: 0;
			border: none;
			background-color: $base;
			transition: transform 0.5s; }
		&:before, &:after {
			content: ''; }
		&:before {
			top: 0; }
		&:after {
			top: 12px; }
		.toggle-left-menu__btn-center {
			top: 6px; } }
	.toggle-left-menu_active {
		color: $base;
		.toggle-left-menu__btn {
			&:before, &:after {
				background-color: $base;
				top: 6px; }
			&:before {
				-webkit-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				transform: rotate(-45deg); }
			&:after {
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg); } }
		.toggle-left-menu__btn-center {
			background-color: $base;
			opacity: 0;
			bottom: 0;
			-webkit-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			transform: rotate(180deg); } } }

@media only screen and (min-width: 992px) {
	.content__aside {
		flex-grow: 1;
		padding: 0 16px;
		width: 30%; }
	.left-nav__selected {
		display: none; }
	.toggle-left-menu {
		margin-bottom: 28px;
		color: #585858; }
	.left-nav__ttl {
		margin-bottom: 24px;
		padding-top: 0;
		padding-bottom: 0; }
	.left-nav {
		display: block !important; } }

@media only screen and (min-width: 1300px) {
	.content__aside {
		width: 25%; } }

@media only screen and (min-width: 1440px) {
	.content__aside {
		width: 24%; } }
