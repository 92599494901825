.str_wrap {
	overflow: hidden;
	width: 100%;
	font-size: 12px;
	line-height: 16px;
	position: relative;
	user-select: none;
	white-space: nowrap; }

.str_move {
	white-space: nowrap;
	position: absolute;
	top: 0;
	left: 0;
	cursor: move; }

.str_move_clone {
	display: inline-block;
	vertical-align: top;
	position: absolute;
	left: 100%;
	top: 0; }

.str_vertical .str_move_clone {
	left: 0;
	top: 100%; }

.str_down .str_move_clone {
	left: 0;
	bottom: 100%; }

.str_vertical .str_move,
.str_down .str_move {
	white-space: normal;
	width: 100%; }

.str_static .str_move,
.no_drag .str_move,
.noStop .str_move {
	cursor: inherit; }

.str_wrap img {
	max-width: none !important; }
